import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  Link,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconPlus, IconUsers } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React from 'react'
import { greeting } from '../../../lib/dayjs'
import { AccountView } from '../../../types/AccountView'
import { Space } from '../../../types/Space'
import { StaticList } from '../../../types/StaticList'
import Avatar from '../../ui/Avatar'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { BuildingIcon } from '../../ui/icons'
import { MiddotDivider } from '../../ui/Middot'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { useCurrentUser } from '../../ui/UserContext'
import { NewListModal } from '../account_views/components/NewListModal'
import { accountViewPath } from '../account_views/lib/list-paths'
import { useTrackRecentNavItems } from '../navigation/useTrackRecentNavItems'
import { SpaceMembers } from '../spaces/components/Members'

type CombinedList = (AccountView | StaticList) & {
  class_name?: string
  permissions?: any
}

function isAccountView(view: CombinedList): view is AccountView {
  return view.class_name === 'AccountView'
}

function _isStaticList(view: CombinedList): view is StaticList {
  return view.class_name === 'StaticList'
}

interface Props {
  lists: CombinedList[]
  spaces: Space[]
}

export default function Home(props: Props) {
  const user = useCurrentUser()
  const newListModal = useDisclosure()

  return (
    <PageLayout size="full" gap={8}>
      <PageTitle skipRendering>Home</PageTitle>

      <Box>
        <NewListModal {...newListModal} />

        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="md" fontWeight="medium">
            {greeting()}, {user.firstName} 👋
          </Heading>

          <Button
            as="a"
            href={projectPath(`/views/new`)}
            onClick={(e) => {
              // prevent the link from navigating
              // unless opening in a new tab
              if (e.ctrlKey || e.metaKey || e.shiftKey) {
                return true
              }

              newListModal.onOpen()
              e.preventDefault()
              return false
            }}
            rounded="md"
            variant="outline"
            leftIcon={<Icon as={IconPlus} boxSize={4} color="purple.600" />}
            iconSpacing={1}
          >
            Create List
          </Button>
        </Flex>
      </Box>

      <Stack spacing={3}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="sm" fontWeight="semibold">
            Lists
          </Heading>

          <Link fontSize="sm" href={projectPath('/views')} color="purple.600" _hover={{ textDecoration: 'underline' }}>
            See all
          </Link>
        </Flex>

        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(auto-fill, minmax(280px, 1fr))']} gap={5}>
          {/* only grab the first few */}
          {props.lists.slice(0, 5).map((list) => (
            <ListCard key={list.id} list={list} />
          ))}
        </Grid>
      </Stack>

      <Divider />

      <Stack spacing={3}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="sm" fontWeight="semibold">
            Spaces
          </Heading>

          <Link fontSize="sm" href={projectPath('/spaces')} color="purple.600" _hover={{ textDecoration: 'underline' }}>
            See all
          </Link>
        </Flex>

        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(auto-fill, minmax(280px, 1fr))']} gap={5}>
          {props.spaces.map((space) => (
            <SpaceCard key={space.id} space={space} />
          ))}
        </Grid>
      </Stack>
    </PageLayout>
  )
}

const nouns = {
  account: 'accounts',
  profile: 'people'
}

function ListCard(props: { list: CombinedList }) {
  const list = props.list
  const href = isAccountView(list) ? accountViewPath(list) : projectPath(`/lists/${list.id}`)
  const trackPageKey = isAccountView(list) ? `accountView:${list.id}` : `staticList:${list.id}`
  const { trackRecentNavItem } = useTrackRecentNavItems()
  const currentUser = useCurrentUser()

  return (
    <LinkBox>
      <Card h="100%" px={5} py={4}>
        <Flex flexDir="column" gap={4} alignItems="flex-start">
          <CircleIcon
            icon={list.kind === 'account' ? BuildingIcon : IconUsers}
            iconSize={18}
            size="32px"
            colorScheme={list.kind === 'account' ? 'purple' : 'blue'}
          />

          <Stack width="100%" spacing={1}>
            <Heading size="xs" fontSize="15px">
              <LinkOverlay
                href={href}
                onClick={() => {
                  trackRecentNavItem(trackPageKey)
                }}
              >
                {list.name}
              </LinkOverlay>
            </Heading>

            <Flex gap={4} alignItems="center" justifyContent="space-between">
              <HStack color="gray.500" fontSize="sm" spacing={1.5} divider={<MiddotDivider />} flexWrap="wrap">
                <Text flex="none" css={{ fontVariantNumeric: 'tabular-nums' }}>
                  {(list.counts?.all ?? 0).toLocaleString()} {pluralize(nouns[list.kind], list.counts?.all || 0)}
                </Text>

                {list.created_by_user && (
                  <Text isTruncated maxW="200px">
                    Created by {getAuthorName(list.created_by_user, currentUser)}
                  </Text>
                )}
              </HStack>
            </Flex>
          </Stack>
        </Flex>
      </Card>
    </LinkBox>
  )
}

function SpaceCard(props: { space: Space }) {
  const space = props.space
  const href = projectPath(`/spaces/${space.id}`)
  const currentUser = useCurrentUser()

  return (
    <LinkBox>
      <Card h="100%" px={5} py={4}>
        <Flex flexDir="column" gap={4} alignItems="flex-start">
          <Avatar size="sm" fontSize="13px" fontWeight="semibold" name={space.name.split(' ')[0]} />

          <Box width="100%">
            <Heading size="xs" fontSize="15px">
              <LinkOverlay href={href}>{space.name}</LinkOverlay>
            </Heading>

            <Flex rowGap={3} columnGap={2} alignItems="center" justifyContent="space-between" flexWrap="wrap">
              <HStack
                color="gray.500"
                fontSize="sm"
                spacing={1.5}
                divider={<MiddotDivider />}
                paddingY={1}
                flexWrap="wrap"
              >
                <Text flex="none" css={{ fontVariantNumeric: 'tabular-nums' }}>
                  {(space.account_views?.length || 0).toLocaleString()}{' '}
                  {pluralize('list', space.account_views?.length || 0)}
                </Text>
                {space.created_by_user && (
                  <Text isTruncated maxW="200px">
                    Created by {getAuthorName(space.created_by_user, currentUser)}
                  </Text>
                )}
              </HStack>

              <SpaceMembers members={space.members} />
            </Flex>
          </Box>
        </Flex>
      </Card>
    </LinkBox>
  )
}

function getAuthorName(user: { id: string; name: string; email: string }, currentUser: { id?: string }) {
  return user.id === currentUser.id ? 'you' : user.name || user.email
}
