import { Box, CircularProgress, Flex, Text, useBreakpointValue, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { useMountedState } from 'react-use'
import { Router, Route, Switch } from 'wouter'
import { ListsNav, ListsNavContext } from './pages/lists/components/ListContainer'
import { SideNav, SideNavContext } from './pages/navigation/SideNav'
import PageLayout from './ui/PageLayout'
import { useCurrentProject } from './ui/ProjectsContext'
import { RootCssVar } from './ui/RootCssVar'
import SettingsNav, { SettingsNavContext } from './ui/SettingsNav'
import { TopBarProvider } from './ui/TopBarContext'
import useLocation from './ui/useLocation'
import useUpdateEffect from './ui/useUpdateEffect'
import createPersistedState from 'use-persisted-state'
import { UserNavSettingsProvider } from './pages/navigation/useTrackRecentNavItems'

type LayoutProps = PropsWithChildren<{
  takingAwhile?: boolean
  loading?: boolean
  path?: string
  from?: string
  supportMode?: boolean
  layoutMode?: string
  onSearchClick?: () => void
}>

export function NewLayout(props: LayoutProps) {
  const project = useCurrentProject()

  if (!project) {
    return <LoadingLayout {...props} />
  }

  const projectPrefix = `/projects/${project.slug}`

  return (
    <Box
      css={`
        --max-container-width: 100vw;
      `}
    >
      <Switch>
        <Router base={projectPrefix}>
          <Switch>
            <Route path={'/settings/*?'}>
              <SettingsLayout {...props} />
            </Route>

            <Route path={'/profiles/imports/new'}>
              <SideNavLayout {...props} />
            </Route>

            <Route path={'/accounts/imports/new'}>
              <SideNavLayout {...props} />
            </Route>

            <Route path={'/imports/*?'}>
              <SettingsLayout {...props} />
            </Route>

            <Route path={'/apps/*?'}>
              <SettingsLayout {...props} />
            </Route>

            <Route path={'/forms/*?'}>
              <SettingsLayout {...props} />
            </Route>

            <Route path={'/utm-reports/*?'}>
              <SettingsLayout {...props} />
            </Route>

            <Route path={'/events/*?'}>
              <SettingsLayout {...props} />
            </Route>

            <Route path={'/scoring'}>
              <SettingsLayout {...props} />
            </Route>

            <Route path={'/instrumentation'}>
              <SettingsLayout {...props} />
            </Route>

            <Route path={'/reports/*?'}>
              <SettingsLayout {...props} />
            </Route>

            <Route path={'/koala-weekly'}>
              <SettingsLayout {...props} />
            </Route>

            <Route path={'/spaces'}>
              <SettingsLayout {...props} />
            </Route>

            <Route path="/welcome">
              <LoadingLayout {...props} />
            </Route>

            <Route>
              <SideNavLayout {...props} />
            </Route>
          </Switch>
        </Router>
        <Route>
          <LoadingLayout {...props} />
        </Route>
      </Switch>
    </Box>
  )
}

export function DefaultLayout(props: LayoutProps) {
  const project = useCurrentProject()

  if (!project) {
    return <LoadingLayout {...props} />
  }

  const projectPrefix = `/projects/${project.slug}`

  return (
    <Switch>
      <Router base={projectPrefix}>
        <Switch>
          <Route path={'/accounts'}>
            <ListsLayout {...props} />
          </Route>
          <Route path={'/accounts/feed'}>
            <ListsLayout {...props} />
          </Route>
          <Route path={'/accounts/live'}>
            <ListsLayout {...props} />
          </Route>
          <Route path={'/visitors'}>
            <ListsLayout {...props} />
          </Route>
          <Route path={'/visitors/feed'}>
            <ListsLayout {...props} />
          </Route>
          <Route path={'/visitors/live'}>
            <ListsLayout {...props} />
          </Route>
          <Route path={'/views/new'}>
            <LoadingLayout {...props} />
          </Route>
          <Route path={'/views/:slug'}>
            {(params: { slug: string }) => <ListsLayout {...props} params={params} />}
          </Route>
          <Route path={'/views/:slug/:mode?'}>
            {(params: { slug: string; mode?: string }) => <ListsLayout {...props} params={params} />}
          </Route>

          <Route path={'/settings/*?'}>
            <SettingsLayout {...props} />
          </Route>

          <Route path={'/apps/*?'}>
            <SettingsLayout {...props} />
          </Route>

          <Route path={'/signals/*?'}>
            <SettingsLayout {...props} />
          </Route>

          <Route path={'/views'}>
            <SettingsLayout {...props} />
          </Route>

          <Route path={'/forms/*?'}>
            <SettingsLayout {...props} />
          </Route>

          <Route path={'/utm-reports/*?'}>
            <SettingsLayout {...props} />
          </Route>

          <Route path={'/events/*?'}>
            <SettingsLayout {...props} />
          </Route>

          <Route path={'/scoring'}>
            <SettingsLayout {...props} />
          </Route>

          <Route path={'/instrumentation'}>
            <SettingsLayout {...props} />
          </Route>

          <Route path={'/reports/*?'}>
            <SettingsLayout {...props} />
          </Route>

          <Route path={'/koala-weekly'}>
            <SettingsLayout {...props} />
          </Route>

          <Route path={'/setup'}>
            <SettingsLayout {...props} />
          </Route>

          <Route>
            <LoadingLayout {...props} />
          </Route>
        </Switch>
      </Router>
      <Route>
        <LoadingLayout {...props} />
      </Route>
    </Switch>
  )
}

// TODO we could let users modify the width later
const sidebarWidthState = createPersistedState<number>('koala:sidebar-width')

function SideNavLayout(props: LayoutProps & { params?: Record<string, string> }) {
  const [sidebarWidth, setSidebarWidth] = sidebarWidthState(250)
  const location = useLocation()
  const mounted = useMountedState()
  const [largeScreen] = useMediaQuery('(min-width: 768px)')
  const smallScreen = !largeScreen
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure({ defaultIsOpen: largeScreen && sidebarWidth > 0 })

  const sidebarState = useMemo(
    () => ({
      isOpen,
      onOpen: () => {
        onOpen()
        setSidebarWidth(250)
      },
      onClose: () => {
        onClose()
        setSidebarWidth(0)
      },
      onToggle: () => {
        if (isOpen) {
          setSidebarWidth(0)
        } else {
          setSidebarWidth(250)
        }

        onToggle()
      },
      isCollapsed: !isOpen && !smallScreen,
      offScreen: smallScreen
    }),
    [isOpen, onOpen, onClose, onToggle, setSidebarWidth, smallScreen]
  )

  // after navigation, close it when on small screens
  useEffect(() => {
    if (smallScreen) {
      onClose()
    }
  }, [location, smallScreen, onClose])

  useUpdateEffect(() => {
    if (largeScreen) {
      if (sidebarWidth > 0) {
        onOpen()
      }
    } else {
      onClose()
    }
  }, [largeScreen, onClose, onOpen])

  const shouldAnimate = mounted() && smallScreen
  const overlay = smallScreen && isOpen

  return (
    <>
      {props.supportMode && (
        <Box position="relative" top={0} left={0} right={0} zIndex="banner">
          <Box
            position="absolute"
            zIndex={6}
            borderTop="2px solid"
            borderColor="orange.200"
            left={0}
            right={0}
            textAlign="center"
            pointerEvents="none"
            lineHeight={0}
          >
            <Text
              display="inline-flex"
              fontWeight="bold"
              fontSize="10px"
              textTransform="uppercase"
              color="orange.700"
              bg="orange.200"
              lineHeight={1}
              px={2}
              py="3px"
              mt="-1px"
              roundedBottom="md"
            >
              Read-only Mode
            </Text>
          </Box>
        </Box>
      )}
      <PageLayout size="full" flush minHeight="min(100vh, var(--content-height))">
        <SideNavContext.Provider value={sidebarState}>
          <TopBarProvider>
            <UserNavSettingsProvider>
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bg="rgba(0, 0, 0, 0.098)"
                opacity={overlay ? 1 : 0}
                transition="opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
                visibility={overlay ? 'visible' : 'hidden'}
                zIndex={overlay ? 100 : 1}
                onClick={overlay ? onClose : undefined}
              />

              <Flex alignItems="stretch" width="100%" height="min(100vh, var(--content-height))">
                <Box position="relative" zIndex="sticky">
                  {/* filler for flex to work */}
                  <Box
                    width={smallScreen ? '0px' : isOpen ? ['240px', '250px', '260px'] : '56px'}
                    transition={shouldAnimate ? 'width 0.2s cubic-bezier(0.4, 0, 0.2, 1)' : undefined}
                  />
                  <SideNav path={location.pathname} onSearchClick={props.onSearchClick} animated={shouldAnimate} />
                </Box>
                <Box
                  position="relative"
                  w="100%"
                  minW="300px"
                  height="100%"
                  overflow="auto"
                  onClick={smallScreen && isOpen ? onClose : undefined}
                >
                  <LoadingLayout {...props} />
                </Box>
              </Flex>
            </UserNavSettingsProvider>
          </TopBarProvider>
        </SideNavContext.Provider>
      </PageLayout>
    </>
  )
}

function ListsLayout(props: LayoutProps & { params?: Record<string, string> }) {
  const location = useLocation()
  const smallScreen = useBreakpointValue({ base: true, sm: true, md: false })
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: !smallScreen })

  const sidebarState = useMemo(
    () => ({
      isOpen,
      onOpen,
      onClose,
      offScreen: smallScreen
    }),
    [isOpen, onOpen, onClose, smallScreen]
  )

  return (
    <PageLayout size="full" flush>
      <ListsNavContext.Provider value={sidebarState}>
        <Flex alignItems="stretch" height="var(--content-height)">
          <ListsNav path={location.pathname} listSlug={props.params?.slug} />
          <Box position="relative" w="100%" minW="300px" height="100%" overflow="auto">
            <LoadingLayout {...props} />
          </Box>
        </Flex>
      </ListsNavContext.Provider>
    </PageLayout>
  )
}

function SettingsLayout(props: LayoutProps & { params?: Record<string, string> }) {
  const location = useLocation()
  const smallScreen = useBreakpointValue({ base: true, sm: true, md: true, lg: false })
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

  const showSidebar = isOpen || !smallScreen

  const sidebarState = useMemo(
    () => ({
      isOpen,
      onOpen,
      onClose,
      onToggle,
      offScreen: smallScreen
    }),
    [isOpen, onOpen, onClose, onToggle, smallScreen]
  )

  useEffect(() => {
    onClose()
  }, [location, onClose])

  useEffect(() => {
    if (!smallScreen) {
      onClose()
    }
  }, [smallScreen, onClose])

  const height = props.layoutMode === 'new' ? '100vh' : 'var(--content-height)'
  const overlay = smallScreen && isOpen

  return (
    <PageLayout size="full" flush minHeight={height}>
      <SettingsNavContext.Provider value={sidebarState}>
        <TopBarProvider>
          <RootCssVar property="--sidebar-width" value={smallScreen ? '0px' : '250px'} />

          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="rgba(0, 0, 0, 0.098)"
            opacity={overlay ? 1 : 0}
            transition="opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1)"
            visibility={overlay ? 'visible' : 'hidden'}
            zIndex={overlay ? 100 : 1}
            onClick={overlay ? onClose : undefined}
          />

          <Flex alignItems="stretch" height={height}>
            <Box position="relative" zIndex="sticky">
              {/* filler for flex to work */}
              <Box width={smallScreen ? '0px' : '250px'} transition="width 0.2s cubic-bezier(0.4, 0, 0.2, 1)" />
              <SettingsNav
                showBack={props.layoutMode === 'new'}
                isOpen={showSidebar}
                collapsible={smallScreen}
                layoutProps={{ height: '100%' }}
              />
            </Box>
            <Box position="relative" w="100%" minW="300px" height="100%" overflow="auto">
              <LoadingLayout {...props} />
            </Box>
          </Flex>
        </TopBarProvider>
      </SettingsNavContext.Provider>
    </PageLayout>
  )
}

function LoadingLayout(props: LayoutProps) {
  // if the current (incoming) location doesnt match the loaded path
  const location = useLocation()
  const differentPage = props.path !== location.pathname

  if (props.loading && differentPage) {
    return (
      <Flex flex="1" w="100%" h="100%" justifyContent="center" alignItems="flex-start" paddingTop="80px">
        <CircularProgress size="8" isIndeterminate color="purple.500" thickness="5px" />
      </Flex>
    )
  }

  return (
    <>
      {/* When we are on the same page (ChildComponent has not unloaded) we should only show the spinner after awhile */}
      {props.takingAwhile && <SpinnerOverlay />}
      {props.children}
    </>
  )
}

function SpinnerOverlay() {
  return (
    <Flex
      justifyContent="center"
      alignItems="flex-start"
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      bottom={0}
      left={0}
      right={0}
      zIndex={1}
      background="rgba(255, 255, 255, 0.5)"
      paddingTop="80px"
    >
      <CircularProgress size="8" isIndeterminate color="purple.500" thickness="5px" />
    </Flex>
  )
}
