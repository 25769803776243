import {
  ButtonProps,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react'
import { IconCheck } from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { App, Apps } from '../../../../types/App'
import { AutoOutboundRule } from '../../../../types/AutoOutbound'
import { GrayCard } from '../../../ui/Card'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { ApolloSetup } from '../../follow_rules/components/apollo/apollo-setup'
import { OutreachSequence } from '../../follow_rules/components/outreach/add-to-sequence'
import { WebhookSetup } from '../../follow_rules/components/webhook/webhook-setup'
import { RoutingRulesSetup } from './definition-form'
import { HubspotRulesSetup } from './hubspot-rules-setup'
import { OutreachRulesSetup } from './outreach-rules-setup'
import { SalesforceRulesSetup } from './salesforce-rules-setup'

interface CampaignDestinationsProps {
  apps: Apps
  outreachSequences?: OutreachSequence[]
  autoOutboundRule?: AutoOutboundRule
  kqlDefinitionIds: string[]
  selectedCRM: App | null
  setSaveDisabled?: (value) => void
}

const DestinationTab = ({
  children,
  comingSoon,
  domain,
  colorScheme = 'purple',
  configured,
  onClick
}: {
  children: React.ReactNode
  comingSoon?: boolean
  domain?: string
  colorScheme?: ButtonProps['colorScheme']
  configured?: boolean
  onClick?: () => void
}) => (
  <Tab
    fontSize={'xs'}
    rounded="md"
    isDisabled={comingSoon}
    onClick={onClick}
    px="3"
    _selected={{
      fontWeight: 'semibold',
      bg: `${colorScheme}.50`,
      borderColor: `${colorScheme}.500`,
      borderWidth: 'thin'
    }}
    _focus={{
      boxShadow: 'none'
    }}
    _active={{
      fontWeight: 'semibold',
      bg: `${colorScheme}.50`,
      borderColor: `${colorScheme}.500`,
      borderWidth: 'thin'
    }}
    _disabled={{
      opacity: 0.5,
      bg: 'gray.50',
      pointerEvents: 'none'
    }}
  >
    <HStack justifyContent={'center'} alignItems="center">
      {domain && <CompanyAvatar domain={domain} size="4" />}
      <Text>{children}</Text>
      {comingSoon && (
        <Flex>
          <Text fontWeight={'semibold'} as={'span'} bg="gray.50" px="1" fontSize={'xx-small'} color="gray.700">
            SOON
          </Text>
        </Flex>
      )}
      {configured && <Icon as={IconCheck} />}
    </HStack>
  </Tab>
)

const destinations = ['outreach', 'hubspot', 'salesforce', 'apollo', 'webhook']

export function CampaignDestinations(props: CampaignDestinationsProps) {
  const { autoOutboundRule, apps, kqlDefinitionIds, outreachSequences } = props
  const [skippingRouting, setSkippingRouting] = useState<boolean>(props.autoOutboundRule?.rules?.routing?.skip ?? false)
  const [rules, setRules] = React.useState<AutoOutboundRule['rules'] | undefined>(autoOutboundRule?.rules)

  useEffect(() => {
    if (autoOutboundRule) {
      setRules(autoOutboundRule.rules)
    }
  }, [autoOutboundRule])

  const defaultIndex = useMemo(() => {
    if (!autoOutboundRule) {
      return -1
    }

    if (autoOutboundRule.rules.outreach) {
      return 0
    }

    const deliveryRules = autoOutboundRule.rules.automation_rules?.delivery_rules ?? {}
    return destinations.findIndex((destination) => !!deliveryRules[destination]) ?? -1
  }, [autoOutboundRule])

  const [tab, setTab] = React.useState<string>(destinations[defaultIndex])

  const requireSender = useMemo(() => {
    return tab === 'outreach' || tab === 'apollo'
  }, [tab])

  return (
    <Stack w="100%" spacing="4">
      <FormControl>
        <FormLabel>Campaign Destinations</FormLabel>
        <FormHelperText>Choose which destination you want to send your matched contacts and accounts to</FormHelperText>
      </FormControl>
      {/* isLazy guarantees that only one destination is setup at a time */}
      <Tabs variant={'unstyled'} size="sm" rounded={'sm'} isLazy defaultIndex={defaultIndex}>
        <TabList fontSize={'xs'}>
          <HStack divider={<Divider orientation="vertical" />}>
            <DestinationTab
              configured={!!rules?.outreach}
              colorScheme={'purple'}
              domain="outreach.io"
              onClick={() => {
                setTab('outreach')
                // remove all other destinations except outreach
                setRules({
                  ...rules,
                  automation_rules: undefined
                })
              }}
            >
              Outreach
            </DestinationTab>
            <DestinationTab
              configured={!!rules?.automation_rules?.delivery_rules?.hubspot}
              colorScheme={'orange'}
              domain="hubspot.com"
              onClick={() => {
                setTab('hubspot')
                // remove all other destinations except hubspot
                setRules({
                  ...rules,
                  outreach: undefined,
                  automation_rules: {
                    delivery_rules: {
                      hubspot: {
                        ...rules?.automation_rules?.delivery_rules?.hubspot
                      }
                    }
                  }
                })
              }}
            >
              HubSpot
            </DestinationTab>
            <DestinationTab
              domain="salesforce.com"
              configured={!!rules?.automation_rules?.delivery_rules?.salesforce}
              colorScheme={'blue'}
              onClick={() => {
                setTab('salesforce')
                // remove all other destinations except Salesforce
                setRules({
                  ...rules,
                  outreach: undefined,
                  automation_rules: {
                    delivery_rules: {
                      salesforce: {
                        ...rules?.automation_rules?.delivery_rules?.salesforce
                      }
                    }
                  }
                })
              }}
            >
              Salesforce
            </DestinationTab>

            <DestinationTab
              domain="apollo.io"
              configured={!!rules?.automation_rules?.delivery_rules?.apollo}
              onClick={() => {
                setTab('apollo')
                // remove all other destinations except Apollo
                setRules({
                  ...rules,
                  outreach: undefined,
                  automation_rules: {
                    delivery_rules: {
                      apollo: {
                        ...rules?.automation_rules?.delivery_rules?.apollo
                      }
                    }
                  }
                })
              }}
            >
              Apollo
            </DestinationTab>
            <DestinationTab
              configured={!!rules?.automation_rules?.delivery_rules?.webhook?.url}
              onClick={() => {
                setTab('webhook')
                // remove all other destinations except Webhooks
                setRules({
                  ...rules,
                  outreach: undefined,
                  automation_rules: {
                    delivery_rules: {
                      webhook: {
                        ...rules?.automation_rules?.delivery_rules?.webhook
                      }
                    }
                  }
                })
              }}
            >
              Webhooks
            </DestinationTab>
          </HStack>
        </TabList>
        {defaultIndex >= 0 && <Divider py="1" />}
        <Stack as={GrayCard} pt="0">
          <TabPanels minH={defaultIndex >= 0 ? '300px' : undefined} p="0">
            <TabPanel px="0">
              <OutreachRulesSetup
                sequences={outreachSequences}
                ruleId={autoOutboundRule?.id}
                apps={apps}
                {...autoOutboundRule?.rules.outreach}
                kqlDefinitionIds={kqlDefinitionIds}
                skippingRouting={skippingRouting}
              />
            </TabPanel>
            <TabPanel>
              <HubspotRulesSetup
                apps={apps}
                ruleId={autoOutboundRule?.id}
                deliveryRules={autoOutboundRule?.rules.automation_rules?.delivery_rules}
              />
            </TabPanel>
            <TabPanel>
              <SalesforceRulesSetup
                ruleId={autoOutboundRule?.id}
                deliveryRules={autoOutboundRule?.rules.automation_rules?.delivery_rules}
                kqlDefinitionIds={kqlDefinitionIds}
                setSaveDisabled={props.setSaveDisabled}
              />
            </TabPanel>
            <TabPanel>
              <ApolloSetup
                delivery_rules={autoOutboundRule?.rules.automation_rules?.delivery_rules}
                kqlDefinitionIds={kqlDefinitionIds}
                actions={{
                  add_to_account_list: true,
                  add_to_sequence: true,
                  add_to_list: true
                }}
              />
            </TabPanel>
            <TabPanel>
              <WebhookSetup
                targetType="Profile"
                delivery_rules={{
                  ...autoOutboundRule?.rules.automation_rules?.delivery_rules,
                  webhook: {
                    ...autoOutboundRule?.rules.automation_rules?.delivery_rules?.webhook
                  }
                }}
                skipTimeTrigger
              />
            </TabPanel>
          </TabPanels>

          {requireSender && (
            <Stack spacing="4">
              <Divider />
              <RoutingRulesSetup
                apps={props.apps}
                {...props.autoOutboundRule?.rules.routing}
                selectedCRM={props.selectedCRM}
                app={tab}
                skip={skippingRouting && !requireSender}
                onSkipChange={setSkippingRouting}
              />
            </Stack>
          )}
          {!requireSender && <input type="hidden" name="routing_rules[skip]" value={'1'} />}
        </Stack>
      </Tabs>
    </Stack>
  )
}
